<template>
  <div>
    <!-- transactionFlowDetailed 交易流水明细 -->
    <en-table-layout :tips="false" :toolbar="false" :tableData="tableData.data" :loading="loading">
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="sn" label="订单编号" />
        <el-table-column prop="goods_name" label="活动名称" />
        <el-table-column label="交易金额">
          <template
            slot-scope="scope"
          >{{ scope.row.trade_money?scope.row.trade_money:0 | unitPrice("￥")}}</template>
        </el-table-column>

        <el-table-column prop="trade_type" label="交易类型">
          <template
            slot-scope="scope"
          >{{ scope.row.trade_type==='WITHDRAWAL'?'提现':scope.row.trade_type==='REFUND'?'退款':'转账'}}</template>
        </el-table-column>
        <el-table-column label="收入">
          <template slot-scope="scope">{{ scope.row.in_money?scope.row.in_money:0 | unitPrice("￥")}}</template>
        </el-table-column>

        <el-table-column label="佣金支出">
          <template
            slot-scope="scope"
          >{{ scope.row.commission?scope.row.commission:0 | unitPrice("￥")}}</template>
        </el-table-column>
        <el-table-column label="交易手续费支出">
          <template
            slot-scope="scope"
          >{{ scope.row.service_charge?scope.row.service_charge:0 | unitPrice("￥")}}</template>
        </el-table-column>

        <el-table-column label="其他费用支出">
          <template
            slot-scope="scope"
          >{{ scope.row.rests_out?scope.row.rests_out:0 | unitPrice("￥")}}</template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_Account from "@/api/account";
// import { RegExp } from "bms-common/ui-utils";

export default {
  name: "transactionFlowDetailed",

  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
        trade_id: this.$route.params.id,
      },
      /** 列表数据 */
      tableData: "",
    };
  },

  mounted() {
    this.GET_List();
    console.log(this.$route.params.id, "1");
  },
  beforeRouteUpdate(to, from, next) {
    this.GET_List();
    this.params.trade_id = to.params.id;
    console.log(this.$route.params, ".id");
    // if (this.$router.params.id)
    next();
  },
  activated() {
    this.params.trade_id = this.$route.params.id;
  },
  watch: {
    "params.trade_id": {
      handler: function () {
        this.GET_List();
      },
      deep: true,
    },
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },
    /** 获取列表 */
    GET_List() {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_Account.getTradeInfo(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.elTabBox {
  ::v-deep .el-tabs__item {
    background: #fff;
  }

  ::v-deep .is-active {
    background: #008080;
    color: #fff;
  }

  ::v-deep #tab-first {
    border-radius: 4px 0 0 4px;
  }

  ::v-deep #tab-second {
    border-radius: 0 4px 4px 0;
  }
}

.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
.inner-toolbar {
  flex-wrap: wrap;
}
div.toolbar-btns {
  display: flex;
  justify-content: inherit;
  width: 100%;
}

div.toolbar-btns .conditions {
  margin-right: 0px;
}
.conditionss {
  margin-top: 5px;
}
</style>
